import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect, useState } from "react";
import { ThreeCircles } from "react-loader-spinner";
import Banner from "./components/Baner";
import BusinessCard from "./components/BusinessCard";
import Description from "./components/Description";
import Footer from "./components/Footer";
import Header from "./components/Header";
import InfoWorking from "./components/InfoWorking";
import Navigation from "./components/Navigation";
import Slider from "./components/Slider";
import "./styles/App.css";

AOS.init({ duration: 1000 });

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="App">
      {loading ? (
        <div className="flex items-center h-screen justify-center">
          <ThreeCircles
            height="100"
            width="100"
            color="#f59e0b"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="three-circles-rotating"
            outerCircleColor=""
            innerCircleColor=""
            middleCircleColor=""
          />
        </div>
      ) : (
        <>
          <Navigation />
          <Header />
          <Description />
          <Banner />
          <InfoWorking />
          <Slider />
          <BusinessCard />
          <Footer />
        </>
      )}
    </div>
  );
}

export default App;
