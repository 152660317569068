import React from "react";
import bannerImage from "../assets/baner/baner.webp";

const Banner = () => {
  return (
    <div className="banner relative w-full border-b-2 border-t-2 border-black  overflow-hidden">
      <img src={bannerImage} alt="Banner" className=" object-cover w-full" />
    </div>
  );
};

export default Banner;
